import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import { BiMenu } from "react-icons/bi";
import "./Navbar.scss";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { selectLanguage } from "../../redux/features/LanguageSlice";
import { Select } from "antd";

const navbarMenuItems = [
  {
    id: 1,
    label: <FormattedMessage id="nav.home" defaultMessage="Trang Chủ" />,
    href: "/",
  },
  {
    id: 2,
    label: <FormattedMessage id="nav.infor" defaultMessage="Thông Tin" />,
    href: "/information",
  },
  {
    id: 3,
    label: <FormattedMessage id="nav.nuts" defaultMessage="Sản Phẩm" />,
    href: "/nuts",
  },
  {
    id: 4,
    label: <FormattedMessage id="nav.services" defaultMessage="Quy Trình" />,
    href: "/services",
  },
  {
    id: 5,
    label: <FormattedMessage id="nav.contact" defaultMessage="Liên Hệ" />,
    href: "/contact",
  },
];

const languageList = [
  {
    value: "vi",
    label: (
      <FormattedMessage id="language.vietnames" defaultMessage="Tiếng Việt" />
    ),
  },
  {
    value: "en",
    label: (
      <FormattedMessage id="language.english" defaultMessage="Tiếng Anh" />
    ),
  },
  {
    value: "cn",
    label: (
      <FormattedMessage id="language.chinese" defaultMessage="Tiếng Trung" />
    ),
  },
];

const NavItemContainer = () => {
  const cartState = useSelector((state) => state.cart);
  const languageState = useSelector((state) => state.language);
  const { locale } = languageState;
  const dispatch = useDispatch();
  const [isNavItem, setIsNavItem] = useState(1);

  // Language
  const handleSelectLanguage = (value) => {
    if (value === "vi") {
      dispatch(selectLanguage("vi"));
    } else if (value === "en") {
      dispatch(selectLanguage("en"));
    } else if (value === "cn") {
      dispatch(selectLanguage("cn"));
    }
  };

  const LanguageSelected = () => {
    return (
      <Select
      className="mt-5 lg:mt-0"
        defaultValue={locale}
        // style={{ width: "auto" }}
        // className="custom_black_outline-button"
        onChange={handleSelectLanguage}
        options={languageList}
      />
    );
  };

  return (
    <>
      {navbarMenuItems.map((item, index) => (
        <li
          key={index}
          className="cursor-pointer text-center w-full xl:w-32 lg:w-28  lg:border-none border-b border-gray-600"
          onClick={() => setIsNavItem(item.id)}
        >
          <Link
            className={`nav_label ${
              isNavItem === item.id ? "nav_label_active" : ""
            }`}
            to={item.href}
          >
            {item.label}
          </Link>
        </li>
      ))}
      {/* <div className="search">
        <div className>
          <input type="text" placeholder="Search..." />
        </div>
      </div> */}
      <div className="buttons text-center">
        {/* <NavLink to="/login" className="custom_black_outline-button py-1 m-1 md:w-20">
          <i className="fa fa-sign-in-alt mr-1"></i> Login
        </NavLink>
        <NavLink to="/register" className="custom_black_outline-button py-1 m-1 md:w-20">
          <i className="fa-solid fa-user-plus mr-1"></i> Register
        </NavLink> */}

        {/* <NavLink
          to="/cart"
          className="custom_black_outline-button py-1 m-1 md:w-22"
        >
          <i className="fa-solid fa-cart-shopping mr-1"></i>{" "}
          <FormattedMessage id="nav.cart" defaultMessage="Giỏ Hàng" /> (
          {cartState.length}){" "}
        </NavLink> */}
        <LanguageSelected />

      </div>
      {/* <div className="custom_orange-button uppercase py-1">
      <a href="/#" >Get A quote</a>
    </div> */}
    </>
  );
};

const Navbar = () => {
  const [isNavMenuMobileOpen, setIsNavMenuMobileOpen] = useState(false);

  return (
    <nav className="w-full bg-background mx-auto">
      <div className="flex justify-between items-center h-24 max-w-[100%] w-full mx-auto px-4 text-black">
        <a className="flex justify-center items-center" href="/#">
          {/* <h1 className="w-full text-3xl font-bold text-primary">HLG.</h1> */}
          <img src={logo} alt="logo" className="w-14 mr-4" />
          <span className="font-bold text-base uppercase lg:text-xl text-center">
            <FormattedMessage
              id="footer.company.1"
              defaultMessage="CÔNG TY TNHH"
            />
            <br />
            <FormattedMessage
              id="footer.company.2"
              defaultMessage="NÔNG SẢN HẢI PHÁT"
            />
          </span>
        </a>

        <ul className="lg:flex hidden justify-center items-center">
          <NavItemContainer />
        </ul>

        <div>
          <BiMenu
            className="cursor-pointer lg:hidden"
            size={20}
            onClick={() => setIsNavMenuMobileOpen(!isNavMenuMobileOpen)}
          />
        </div>
        
        <div
          className={
            isNavMenuMobileOpen
              ? "fixed left-0 top-0 w-[80%] h-full border-r border-r-gray-900 ease-in-out duration-500 bg-white z-10"
              : "fixed left-[-200%] ease-in-out duration-500"
          }
        >
          <div className="flex mx-5">
            <img src={logo} alt="logo" className="w-11 object-contain mr-5" />
            <h1 className="w-full text-2xl font-bold text-primary pt-4">
              <FormattedMessage
                id="footer.company"
                defaultMessage="CÔNG TY TNHH NÔNG SẢN HẢI PHÁT"
              />
            </h1>
          </div>

          <ul className="uppercase p-4">
            <NavItemContainer />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
